import React, { useState, useEffect, useRef } from "react";
import { FaPlay } from "react-icons/fa";
import { TbPlayerPauseFilled } from "react-icons/tb";

const Playback = ({totalDuration, onPlay, onStop}) => {
  const [duration, setDuration] = useState(0); // Duration of the progress bar in seconds
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const intervalRef = useRef(null);

  useEffect(()=>{
    if(totalDuration){
        setDuration(totalDuration)
    }
  },[totalDuration])

  useEffect(() => {
    if (isPlaying) {
      intervalRef.current = setInterval(() => {
        setCurrentTime((prevTime) => {
          if (prevTime < duration) {
            return prevTime + 1;
          } else {
            clearInterval(intervalRef.current);
            setCurrentTime(0)
            setIsPlaying(false)
            return duration;
          }
        });
      }, 1000);
    } else {
      clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
  }, [isPlaying, duration]);


  const progress = (currentTime / duration) * 100;

  return (
    <div className="w-[90%] px-10 pt-5">
      <div className="flex items-center">
        <button className="play-button">
          {isPlaying ? <TbPlayerPauseFilled onClick={()=>{
            setIsPlaying(false);
            onStop()
          }} /> : <FaPlay onClick={()=>{
            setIsPlaying(true);
            onPlay()
          }}
          />}
        </button>
        <div className="progress-bar">
          <div className="progress" style={{ width: `${progress}%` }}></div>
          <div
            className="progress-circle"
            style={{ left: `${progress}%` }}
          ></div>
        </div>
      </div>
      <div className="timer">
        {`${Math.floor(currentTime / 60)}:${currentTime % 60 < 10 ? "0" : ""}${
          currentTime % 60
        }/${Math.floor(duration / 60)}:${duration % 60 < 10 ? "0" : ""}${
          duration % 60
        }`}
      </div>
    </div>
  );
};

export default Playback;
