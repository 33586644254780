import React from 'react'

function Card({title, value}) {
  return (
    <div className='w-[150px] h-[150px] bg-[#ececec] flex flex-col items-center justify-center px-3'>
        <p className='text-[14px] text-[#82ca9d] text-center'>{title}</p>
        <p className='mt-3 text-[20px] text-center'>{value}</p>
    </div>
  )
}

export default Card