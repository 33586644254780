import React, { useContext, useEffect, useState } from "react";
import MyLineChart from "../LineChart";
import Progressbar from "../Common/progressbar";
import { ContextApi } from "../../App";
import DateFilter from "../Common/DateFilter";
import moment from "moment";

const ProgressbarList = [
  { id: 1, name: "Status Start", color: "#FFC200", average: 116, icon: "" },
  { id: 2, name: "Status Start", color: "#FF9000", average: 70, icon: "" },
  { id: 3, name: "Harsh Driving", color: "#C91E40", average: 100, icon: "" },
  {
    id: 4,
    name: "Harsh Acceleration",
    color: "#389CFF",
    average: 200,
    icon: "",
  },
  { id: 4, name: "Night Driving", color: "#059F02", average: 150, icon: "" },
];

const TableHeader = [
  "Event Name",
  "Time",
  "Vehicle",
  "Driver",
  "Value",
  "Threshold",
  "Severity",
  "Speed",
  "Address",
];

const TableValues = [
  {
    id: 1,
    eventName: "Status Start",
    time: "29/05/24 23:25:24",
    Vehicle: "UBB 1489",
    driver: "Jack",
    value: "Moving",
    threshold: "",
    severity: "Advisory",
    speed: "11km/hr",
    address: "Google",
  },
  {
    id: 2,
    eventName: "Status Start",
    time: "29/05/24 23:25:24",
    Vehicle: "UBB 1489",
    driver: "Jack",
    value: "Moving",
    threshold: "",
    severity: "Advisory",
    speed: "11km/hr",
    address: "Google",
  },
  {
    id: 3,
    eventName: "Status Start",
    time: "29/05/24 23:25:24",
    Vehicle: "UBB 1489",
    driver: "Jack",
    value: "Moving",
    threshold: "",
    severity: "Advisory",
    speed: "11km/hr",
    address: "Google",
  },
  {
    id: 4,
    eventName: "Status Start",
    time: "29/05/24 23:25:24",
    Vehicle: "UBB 1489",
    driver: "Jack",
    value: "Moving",
    threshold: "",
    severity: "Advisory",
    speed: "11km/hr",
    address: "Google",
  },
  {
    id: 5,
    eventName: "Status Start",
    time: "29/05/24 23:25:24",
    Vehicle: "UBB 1489",
    driver: "Jack",
    value: "Moving",
    threshold: "",
    severity: "Advisory",
    speed: "11km/hr",
    address: "Google",
  },
  {
    id: 6,
    eventName: "Status Start",
    time: "29/05/24 23:25:24",
    Vehicle: "UBB 1489",
    driver: "Jack",
    value: "Moving",
    threshold: "",
    severity: "Advisory",
    speed: "11km/hr",
    address: "Google",
  },
];

function EventsComponent() {
  const { activeDevice } = useContext(ContextApi);
  const [deviceTrips, setDeviceTrips] = useState([]);

  const handleNavigate = (item) => {
    const url = `/map?lat=${item?.latitude}&long=${item?.longitude}`
    window.open(url,"_blank")
  }

  return (
    <>
      <DateFilter
        activeDevice={activeDevice.deviceId}
        setDeviceTrips={setDeviceTrips}
        baseUrl={"api/reports/events"}
      />
      <div className="ml-5 grid grid-cols-3 gap-5 h-[460px]">
        <div className="col-span-2">
          <MyLineChart />
        </div>
        <div className="bg-white p-5 rounded-md">
          <p className="text-[20px] font-bold flex items-center justify-between mb-5">
            <span>Most Common Events</span>
            <span>2115</span>
          </p>
          {ProgressbarList &&
            ProgressbarList.length > 0 &&
            ProgressbarList.map((item, index) => {
              return (
                <div key={index} className="mb-5">
                  <Progressbar item={item} />
                </div>
              );
            })}
        </div>
      </div>
      <div className="ml-5 bg-white py-5 mt-5">
        <p className="text-[20px] font-bold py-5 pl-10">Events Tables</p>
        <table className="table-auto bg-white rounded-md">
          <thead>
            <tr className="bg-[#180E45] text-white">
              {TableHeader &&
                TableHeader.length > 0 &&
                TableHeader.map((item, index) => {
                  return <th className="w-[90px]" key={index}>{item}</th>;
                })}
            </tr>
          </thead>
          <tbody>
            {deviceTrips &&
              deviceTrips.length > 0 &&
              deviceTrips.map((item, index) => {
                let isSplit = "";
                if (item?.type.includes("device")) {
                  isSplit = "device";
                } else if (item?.type.includes("driver")) {
                  isSplit = "driver";
                } else {
                  isSplit = "";
                }
                const splitName = isSplit != "" ? item?.type.split(isSplit) : [];
                return (
                  <tr key={index} className="pt-5 border mb-5">
                    <td className="capitalize w-[85px] whitespace-normal">
                      {splitName.length > 1 ? splitName[1] : ""}
                    </td>
                    <td className="w-[85px] whitespace-normal">
                      {item?.eventTime
                        ? moment(item.eventTime).format("DD/MM/YYYY hh:mm:ss")
                        : ""}
                    </td>
                    <td className="w-[85px] whitespace-normal">{activeDevice?.name ?? ""}</td>
                    <td className="w-[85px] whitespace-normal">{item?.driver ?? ""}</td>
                    <td className="w-[85px] whitespace-normal">{item?.value ?? "" ?? ""}</td>
                    <td className="w-[85px] whitespace-normal">{item?.threshold ?? ""}</td>
                    <td className="w-[85px] whitespace-normal">{item?.severity ?? ""}</td>
                    <td className="w-[85px] whitespace-normal">{`${activeDevice?.speed} Km/H` ?? "0 Km/H"}</td>
                    <td className="w-[85px] whitespace-normal underline text-[green] cursor-pointer" onClick={()=>{
                      handleNavigate(activeDevice)
                    }}>Google map</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default EventsComponent;
