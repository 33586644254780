import React, { createContext, useState, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./Pages/Login";
import Detail from "./Pages/Detail";
import DeepDive from "./Pages/DeepDive";
import EventMap from "./Components/EventsComponents/eventMap";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { getCookie } from "./services/apis";

export const ContextApi = createContext();

function App() {
  const [activeDevice, setActiveDevice] = useState("");
  const [messageHistory, setMessageHistory] = useState([]);
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/detail",
      element: <Detail />,
    },
    {
      path: "/deepDive",
      element: <DeepDive />,
    },
    {
      path: "/map",
      element: <EventMap />,
    },
  ]);

  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
    process.env.REACT_APP_SOCKET_URL,
    {
      onOpen: () => {
        console.log("WebSocket connection opened.");

        const GETJSESSIONID = getCookie("JSESSIONID");
        if (GETJSESSIONID) {
          sendMessage({ type: "auth", Cookie: `JSESSIONID=${GETJSESSIONID}` });
        }
      },
      onError: (event) => {
        console.error("WebSocket error observed:", event);
      },
      onClose: () => {
        console.log("WebSocket connection closed.");
      },
      shouldReconnect: (closeEvent) => true,
    }
  );

  useEffect(() => {
    if (lastMessage !== null) {
      console.log(lastMessage,"lastMessage")
    }
  }, [lastMessage]);


  return (
    <ContextApi.Provider
      value={{
        activeDevice,
        setActiveDevice
      }}
    >
      <div className="h-[100vh] w-full overflow-y-hidden bg-[#F5F5F5]">
        <RouterProvider router={router} />
      </div>
    </ContextApi.Provider>
  );
}

export default App;
