import React, { useEffect, useState } from "react";

function Input({ name, logo, onchange, placeholder, Error, setError, type }) {
  const [value, setValue] = useState("");
  const [fieldError, setFieldError] = useState("")

  useEffect(()=>{
    if(Error){
      console.log(Error,"Error")
      setFieldError(Error[name])
    }
  },[Error])

  return (
    <>
      <div className="h-[60px] w-full rounded-xl flex items-center px-5 border border-[#7B6F72]">
        {logo && logo}
        <input
        type={type}
          className="border-none outline-none h-full ml-3 bg-transparent"
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={(e) => {
            setValue(e.target.value)
            onchange(name,e.target.value)
          }}
          onFocus={() => {
            setError({...Error, [name]: ""})
          }}
        />
      </div>
      {fieldError && fieldError != "" && <p className="my-2 text-[14px] text-[red]">{fieldError}</p>}
    </>
  );
}

export default Input;
