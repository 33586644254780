import React, { useEffect, useState } from "react";
import GoogleMap from "google-maps-react-markers";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useLocation } from "react-router-dom";

const Marker = ({ text }) => (
  <div className="marker-icon grow">
    <FaMapMarkerAlt />
  </div>
);

function EventMap() {
  const location = useLocation();
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const latitute = searchParams.get("lat");
    const longitute = searchParams.get("long");

    setLat(latitute);
    setLong(longitute);
  }, [location.search]);

  return (
    <div className="w-[100%] h-[100vh]">
      {lat != "" && long != "" && (
        <GoogleMap
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          defaultCenter={{
            lat: lat,
            lng: long,
          }}
          defaultZoom={14}
        >
          <Marker lat={lat} lng={long} />
        </GoogleMap>
      )}
    </div>
  );
}

export default EventMap;
