import React, { useContext, useState } from "react";
import {
  YellowCar,
  Overview,
  Tips,
  Behaviour,
  Fuel,
  Efficiency,
  Maintenance,
  YellowReportsLogo,
  Test,
  DownArrow,
} from "../../assets/svgLogos";
import { ContextApi } from "../../App";
import DateFilter from "../Common/DateFilter";
import Trips from "../TabsPages/trips";
import MaintenanceComponent from "../TabsPages/Maintenance";
import FuelComponent from "../TabsPages/fuel";
import LiveOverview from "../TabsPages/liveOverview";
import EfficiencyComponent from "../TabsPages/efficiency";
import DriverBehaviour from "../TabsPages/driverBehaviour";

const DefaultReportOptions = [
  {
    id: 1,
    name: "Live Overview",
    logo: Overview,
    subOptions: false,
  },
  { id: 2, name: "Trips", logo: Tips, subOptions: false, parentId: 1 },
  {
    id: 3,
    name: "Driver Behavior",
    logo: Behaviour,
    subOptions: false,
  },
  { id: 4, name: "Fuel", logo: Fuel, subOptions: false, parentId: 1 },
  {
    id: 5,
    name: "Efficiency",
    logo: Efficiency,
    subOptions: false,
  },
  {
    id: 6,
    name: "Maintenance",
    logo: Maintenance,
    subOptions: false,
  },
];

const MyReportsOption = [
  { id: 7, name: "Test", logo: Test, subOptions: false },
]

function ReportsComponent() {
  const { activeDevice } = useContext(ContextApi);
  const [activeOption, setActiveOption] = useState("Live Overview");
  const [deviceTrips, setDeviceTrips] = useState([]);
  const [defaultReport, setDefaultReport] = useState(true);
  const [myReport, setMyReport] = useState(false);

  return (
    <>
      <div className="w-full flex">
        <div className="min-w-[380px] min-h-[79vh] border-2 ml-2 bg-white">
          <p className="text-[18px] font-bold text-[#180E45] px-6 py-4">
            Report List
          </p>
          <div
            className={`flex items-center justify-between cursor-pointer px-6 py-4 bg-[transparent] text-black`}
            onClick={() => {setDefaultReport(!defaultReport)}}
          >
            <div className="flex items-center gap-x-5">
              <span className="w-[30px]">
                <YellowCar />
              </span>
              <span className="text-[15px]">Default Reports</span>
            </div>
              <div className={`${defaultReport === true ? "rotate-180" : "rotate-0"}`}>
                <DownArrow color="#FFC200" />
              </div>
          </div>
          <div className={`${defaultReport === true ? "h-auto" : "h-0"} overflow-hidden`}>
            {DefaultReportOptions && DefaultReportOptions.length > 0 && (
              <ul>
                {DefaultReportOptions.map((item, index) => {
                  const Logo = item?.logo
                  return (
                    <li
                      key={index}
                      className={`flex items-center justify-between cursor-pointer px-6 py-4 ${
                        activeOption === item?.name
                          ? "bg-[#ffc200] text-white"
                          : "bg-[transparent] text-black"
                      }`}
                      onClick={() => {
                        setActiveOption(item?.name);
                      }}
                    >
                      <div className="flex items-center gap-x-5">
                        <span className="w-[30px]"><Logo color={`${activeOption == item?.name ? "white" : "#FFC200"}`} /></span>
                        <span className="text-[15px]">{item.name}</span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          <div
            className={`flex items-center justify-between cursor-pointer px-6 py-4 bg-[transparent] text-black`}
            onClick={() => {setMyReport(!myReport)}}
          >
            <div className="flex items-center gap-x-5">
              <span className="w-[30px]">
              <YellowReportsLogo />
              </span>
              <span className="text-[15px]">My Reports</span>
            </div>
              <div className={`${myReport === true ? "rotate-180" : "rotate-0"}`}>
                <DownArrow color="#FFC200" />
              </div>
          </div>
          <div className={`${myReport === true ? "h-auto" : "h-0"} overflow-hidden`}>
            {MyReportsOption && MyReportsOption.length > 0 && (
              <ul>
                {MyReportsOption.map((item, index) => {
                  const Logo = item?.logo
                  return (
                    <li
                      key={index}
                      className={`flex items-center justify-between cursor-pointer px-6 py-4 ${
                        activeOption === item?.name
                          ? "bg-[#ffc200] text-white"
                          : "bg-[transparent] text-black"
                      }`}
                      onClick={() => {
                        setActiveOption(item?.name);
                      }}
                    >
                      <div className="flex items-center gap-x-5">
                        <span className="w-[30px]"><Logo color={`${activeOption == item?.name ? "white" : "#FFC200"}`}/></span>
                        <span className="text-[15px]">{item.name}</span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
        <div className="w-[100%]">
          {activeDevice &&
            activeDevice !== "" &&
            (activeOption === "Trips" ||
              activeOption === "Fuel" ||
              activeOption === "Efficiency" ||
              activeOption === "Driver Behavior") && (
              <DateFilter
                activeDevice={activeDevice}
                setDeviceTrips={setDeviceTrips}
                activeTab={activeOption}
                baseUrl={"api/reports/trips"}
              />
            )}
          {activeOption && activeOption === "Trips" && (
            <Trips
              activeDevice={activeDevice}
              data={deviceTrips}
              title={"Trips"}
            />
          )}
          {activeOption && activeOption === "Fuel" && (
            <FuelComponent
              activeDevice={activeDevice}
              data={[]}
              title={"Fuel"}
            />
          )}
          {activeOption && activeOption === "Efficiency" && (
            <EfficiencyComponent
              activeDevice={activeDevice}
              data={deviceTrips}
              title={"Efficiency"}
            />
          )}
          {activeOption && activeOption === "Driver Behavior" && (
            <DriverBehaviour
              activeDevice={activeDevice}
              data={deviceTrips}
              title={"Driver Behavior"}
            />
          )}
          {activeOption && activeOption === "Maintenance" && (
            <MaintenanceComponent activeDevice={activeDevice} title={"Maintenance"} />
          )}
          {activeOption && activeOption === "Live Overview" && (
            <LiveOverview activeDevice={activeDevice} title={"Live Overview"} />
          )}
          {activeOption && activeOption === "Test" && (
            <MaintenanceComponent activeDevice={activeDevice} title={"Test"} />
          )}
        </div>
      </div>
    </>
  );
}

export default ReportsComponent;
