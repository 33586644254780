import React, { useState, useEffect } from "react";
import { Vehicles, Truck, Bus, Locomotive } from "../../assets/svgLogos";
import Piechart from "../Piechart/DonutChart";
import Progressbar from "../Common/progressbar";
import PlaybackImg from "../../assets/images/playback.svg";
import moment from "moment";
import Card from "../Common/Card";

const ProgressbarList = [
  {
    id: 1,
    name: "Vehicle",
    color: "#FFC200",
    average: 116,
    icon: <Vehicles />,
  },
  { id: 2, name: "Truck", color: "#FFC200", average: 20, icon: <Truck /> },
  { id: 3, name: "Bus", color: "#FFC200", average: 19, icon: <Bus /> },
  {
    id: 4,
    name: "Locomotive",
    color: "#FFC200",
    average: 1,
    icon: <Locomotive />,
  },
];

const TableHeader = [
  "Vehicle",
  "Start time",
  "End time",
  "Start Address",
  "End Address",
  "Trip duration (h)",
  "Idle time (h)",
];

function Trips({ activeDevice, data, title }) {
  const [tableData, setTableData] = useState([]);
  const [summaryDetail, setSummaryDetail] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  const handleNavigate = (item) => {
    if(title === "Trips"){
      const url = `/deepDive?deviceId=${item?.deviceId}&deviceName=${item?.deviceName}&start=${item?.startTime}&end=${item?.endTime}`;
      window.open(url, "_blank");
    }
  };

  useEffect(() => {
    if (data && data.length > 0) {
      let totalTrips = data.length;
      let activeOption = activeDevice?.group ?? 1;
      let totalDistance = 0;
      let averageDistance = 0;
      let totalDuration = 0;
      let averageDuration = 0;
      data.map((item) => {
        if (item?.distance) {
          totalDistance += Math.round(item.distance);
        }
        if (item?.duration) {
          totalDuration += Math.round(item?.duration);
        }
      });
      averageDistance = Math.round(totalDistance / totalTrips);
      averageDuration = Math.round(totalDuration / totalTrips);
      setSummaryDetail([
        { title: "No. of trips", value: totalTrips },
        { title: "Active vehicles", value: activeOption },
        { title: "Distance (Km)", value: totalDistance },
        { title: "Avg. trip distance", value: averageDistance },
        { title: "Total duration (h)", value: totalDuration },
        { title: "Avg. trip duration (h)", value: averageDuration },
      ]);
    }else{
      setSummaryDetail([
        { title: "No. of trips", value: 0 },
        { title: "Active vehicles", value: 0 },
        { title: "Distance (Km)", value: 0 },
        { title: "Avg. trip distance", value: 0 },
        { title: "Total duration (h)", value: 0 },
        { title: "Avg. trip duration (h)", value: 0 },
      ]);
    }
  }, [data]);

  return (
    <>
      {tableData && tableData.length > 0 && (
        <div className="w-full h-full ml-5 overflow-x-hidden">
          <p className="font-bold text-[18px] mb-3 mt-5">Summary</p>
          <div className="flex gap-5">
            <Piechart data={{title: activeDevice?.name, color: "#82ca9d", centerText: "Most Active Vehicle"}} />
            <div className="mb-5 flex flex-wrap gap-5">
            {summaryDetail &&
              summaryDetail.length > 0 &&
              summaryDetail.map((item, index) => {
                return (
                  <Card key={index} title={item?.title} value={item?.value} />
                );
              })}
          </div>
          </div>
          <div className="max-w-[1000px] overflow-x-auto py-5 table-container">
            <table className="table-auto bg-white rounded-md">
              <thead>
                <tr className="bg-[#180E45] text-white">
                  {TableHeader &&
                    TableHeader.length > 0 &&
                    TableHeader.map((item, index) => {
                      return (
                        <th key={index} className="text-center px-10">
                          {item}
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                {tableData &&
                  tableData.length > 0 &&
                  tableData.map((item, index) => {
                    return (
                      <tr key={index} className="border">
                        <td className="px-10 text-center">
                          <div className="flex items-center gap-2 w-[170px]">
                            <img
                              onClick={() => {
                                handleNavigate(item);
                              }}
                              src={PlaybackImg}
                              title="Deep Dive"
                              className="w-[15px] h-[15px] cursor-pointer"
                              alt=""
                            />
                            <span className="whitespace-normal">
                              {item?.deviceName ?? "N/A"}
                            </span>
                          </div>
                        </td>
                        <td className="px-10 whitespace-normal text-center">
                          {item?.startTime
                            ? moment(item.startTime).format(
                                "DD/MM/YYYY hh:mm:ss"
                              )
                            : "N/A"}
                        </td>
                        <td className="px-10 whitespace-normal text-center">
                          {item?.endTime
                            ? moment(item.endTime).format("DD/MM/YYYY hh:mm:ss")
                            : "N/A"}
                        </td>
                        <td className="px-10 whitespace-normal text-center">
                          {item?.startAddress ?? "N/A"}
                        </td>
                        <td className="px-10 whitespace-normal text-center">
                          {item?.endAddress ?? "N/A"}
                        </td>
                        <td className="px-10 whitespace-normal text-center">
                          {item?.duration ?? "N/A"}
                        </td>
                        <td className="px-10 whitespace-normal text-center">
                          N/A
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default Trips;
