import React from "react";

const DonutChart = ({ data }) => {
  const COLORS = [data?.color];
  return (
    <div className="max-w-[400px] max-h-[330px] bg-white p-5 rounded-sm relative">
      <div className="flex items-center gap-2 justify-center mb-5">
        <div
          className={`w-[15px] h-[15px]`}
          style={{ background: `${COLORS}` }}
        ></div>
        <p className={`text-[16px] font-bold text-[#666]`}>{data?.title}</p>
      </div>
      <div
        className="w-[250px] h-[250px] bg-white rounded-full relative"
        style={{ border: `45px solid ${COLORS}` }}
      >
        {data?.icon === true ? (
           <p className="absolute top-[20px] left-[50%] transform z-20 font-bold text-[30px] text-center">
           {data?.centerText}
         </p>
        ): (
          <p className="absolute top-[15%] left-[45%] transform z-20 font-bold text-[10px] text-center">
          {data?.centerText}
        </p>
        )}
        <div className={`text-white px-2 py-1 absolute top-[45%] left-[-50px] z-10`} style={{background: COLORS}}>
          100%
        </div>
      </div>
    </div>
  );
};

export default DonutChart;
