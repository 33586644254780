import React, { useEffect, useState } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { IoMdArrowDropdown } from "react-icons/io";
import { DatePicker } from "antd";
import { GetReportApi } from "../../services/apis";
import moment from "moment";
const { RangePicker } = DatePicker;

export default function Dropdown({
  activeDevice,
  setDeviceTrips,
  baseUrl,
  activeTab,
}) {
  const [activeOption, setActiveOption] = useState("Last Day");
  const [date, setDate] = useState([]);
  const [loading, setLoading] = useState(false);

  const onChange = (dates, dateStrings) => {
    setDate(dateStrings);
  };

  const ApiResponse = (res) => {
    if (res) {
      setDeviceTrips(res);
      setLoading(false);
    }
  };

  const formatDateString = (dateString) => {
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };

  const handleReports = async () => {
    setLoading(true);
    const formatedDate = [];
    date.map((item) => {
      const formatDate = formatDateString(item);
      formatedDate.push(`${formatDate}T00:00:00Z`);
    });
    await GetReportApi(
      baseUrl,
      formatedDate,
      activeDevice.deviceId,
      ApiResponse
    );
  };

  useEffect(() => {
    if (activeOption === "Last Day") {
      const today = moment();
      const formattedToday = today.format("DD-MM-YYYY");
      const yesterday = moment().subtract(1, "days");
      const formattedYesterday = yesterday.format("DD-MM-YYYY");
      setDate([formattedYesterday, formattedToday]);
    } else if (activeOption === "Last Week") {
      const today = moment();
      const formattedToday = today.format("DD-MM-YYYY");
      const yesterday = moment().subtract(7, "days");
      const formattedYesterday = yesterday.format("DD-MM-YYYY");
      setDate([formattedYesterday, formattedToday]);
    }
  }, [activeOption]);

  return (
    <div className="w-full mb-5 pl-3">
      <div className="w-full bg-white h-[50px] flex justify-end items-center px-5">
        <div className="flex gap-x-3">
          <div className="flex items-center gap-x-3">
            <p>Reports: </p>
          </div>
          <div>
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <MenuButton className="inline-flex justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-[170px]">
                  {activeOption}
                  <IoMdArrowDropdown className="-mr-1 h-5 w-5 text-gray-400" />
                </MenuButton>
              </div>

              <MenuItems
                transition
                className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <div className="py-1">
                  <MenuItem>
                    <div
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                      onClick={() => {
                        setActiveOption("Last Day");
                      }}
                    >
                      Last Day
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <div
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                      onClick={() => {
                        setActiveOption("Last Week");
                      }}
                    >
                      Last Week
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <div
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                      onClick={() => {
                        setActiveOption("Custom");
                      }}
                    >
                      Custom
                    </div>
                  </MenuItem>
                </div>
              </MenuItems>
            </Menu>
          </div>
          <div className="h-[35px]">
            <RangePicker
              onChange={onChange}
              format="DD-MM-YYYY"
              className="h-full"
              disabled={activeOption !== "Custom"}
            />
          </div>
          <button
            disabled={loading}
            className="h-[35px] bg-[#180e45] text-white rounded-md px-5 flex items-center gap-2"
            onClick={() => {
              handleReports();
            }}
          >
            {loading == true && <div className="small_loader"></div>}
            Execute
          </button>
        </div>
      </div>
      <div className="flex items-center gap-x-20 p-5 border-t border-b">
        <p>
          {activeTab}- {activeDevice?.name}
        </p>
        <p>
          Total Selected Vehicle:{" "}
          {activeDevice && !activeDevice?.group ? 1 : ""}
        </p>
      </div>
    </div>
  );
}
