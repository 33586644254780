import React, { useState, useEffect, useContext } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import { MapLogo, EventsLogo, ReportsLogo } from "../../assets/svgLogos";
import MapComponent from "../../Components/MapComponent";
import ReportsComponent from "../../Components/ReportsComponent";
import EventsComponent from "../../Components/EventsComponents";
import { GetApi } from "../../services/apis";
import { ContextApi } from "../../App";
// import socketIOClient from 'socket.io-client';

function Map() {
  const { activeDevice } = useContext(ContextApi);
  const [tabs, setTabs] = useState([
    { id: 1, name: "Map", logo: "MapLogo", isActive: true },
    { id: 2, name: "Reports", logo: "ReportLogo", isActive: false },
    { id: 3, name: "Events", logo: "EventsLogo", isActive: false },
  ]);
  const [activeTab, setActiveTab] = useState(1);
  const [devices, setDevices] = useState([]);

  const ApiResponse = (res) => {
    if (res && res.data) {
      setDevices(res.data.length > 0 ? res.data : []);
    }
  };

  useEffect(() => {
    GetApi("api/devices", ApiResponse);
  }, []);
//   useEffect(() => {
//     const socket = socketIOClient(process.env.REACT_APP_BASEURL);

//     // Listen for car devices data from the server
//     socket.on('carDevices', (data) => {
//         console.log(data,"data")
//     });

//     // Clean up on component unmount
//     return () => socket.disconnect();
// }, []);

  const handleTabs = (id) => {
    const allTabs = tabs;
    if (allTabs && allTabs.length > 0) {
      allTabs.map((items) => {
        if (items?.id === id) {
          items.isActive = true;
        } else {
          items.isActive = false;
        }
      });
      setTabs([...allTabs]);
    }
  };
  return (
    <div className="w-full pb-10 h-[100vh]">
      <Navbar />
      <div className="px-20 flex mt-6 relative h-[88vh] overflow-y-auto overflow-x-hidden">
        <div className="h-[88vh] w-[385px] rounded-sm border-2 bg-white sidebar fixed top-30 left-30 z-30">
          <Sidebar devices={devices} activeTab={activeTab} />
        </div>
        <div className="w-full tabsSection ml-[385px]">
          <div className="flex justify-center items-center gap-x-10">
            {tabs && tabs.length > 0 && (
              <>
                {tabs.map((item, index) => {
                  return (
                    <button
                      key={index}
                      className={`${
                        item.isActive == true
                          ? "bg-[#180E45] text-[white]"
                          : "text-[#180E45] border-2 border-[#180E45]"
                      } w-[220px] h-[60px] flex items-center justify-center gap-x-2 text-[17px] font-semibold uppercase`}
                      style={{ borderRadius: "30px" }}
                      onClick={() => {
                        handleTabs(item?.id);
                        setActiveTab(item?.id);
                      }}
                    >
                      {item.name === "Map" && (
                        <MapLogo
                          color={`${
                            item?.isActive === true ? "white" : "#1E1E1E"
                          }`}
                        />
                      )}
                      {item.name === "Reports" && (
                        <ReportsLogo
                          color={`${
                            item?.isActive === true ? "white" : "#1E1E1E"
                          }`}
                        />
                      )}
                      {item.name === "Events" && (
                        <EventsLogo
                          color={`${
                            item?.isActive === true ? "white" : "#1E1E1E"
                          }`}
                        />
                      )}
                      {item?.name}
                    </button>
                  );
                })}
              </>
            )}
          </div>
          <div className="w-full h-[96vh] mt-5">
            {activeTab === 1 && <MapComponent activeDevice={activeDevice} />}
            {activeTab === 2 && <ReportsComponent />}
            {activeTab === 3 && <EventsComponent />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Map;
