import axios from "axios";
import { Base64 } from "js-base64";
import Cookies from "js-cookie";

export const PostApi = async (
  url = "",
  data = {},
  setLoading = () => {},
  ApiResponse = () => {}
) => {
  const Body = Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");

  try {
    const response = await axios.post(`${process.env.REACT_APP_BASEURL}${url}`, Body, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    if (response) {
      ApiResponse(response);
    }
  } catch (err) {
    console.log('Error:', err);
  } finally {
    setLoading(false);
  }
};

export const GetApi = async (url, ApiResponse = () => {}) => {
  let credentials = "";
  const getEmail = getCookie("email");
  const getPassword = getCookie("token");
  if (getPassword) {
    const encode = decodeData(getPassword);
    if (encode && getEmail) {
      credentials = btoa(`${getEmail}:${encode}`);
    }
  }

  let config = {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  };

  try {
    const response = await axios.get(`${process.env.REACT_APP_BASEURL}${url}`, config);
    if (response) {
      ApiResponse(response);
      return response
    }
  } catch (err) {
    console.log(err, "err");
    return err
  }
};

export const GetReportApi = async (url, date,activeDevice, ApiResponse = () => {}, setLoading=()=>{}) => {
  let credentials = "";
  let from = "";
  let to = "";

  const getEmail = getCookie("email");
  const getPassword = getCookie("token");
  if (getPassword) {
    const encode = decodeData(getPassword);
    if (encode && getEmail) {
      credentials = btoa(`${getEmail}:${encode}`);
    }
  }
  if(date && date.length > 0){
    to = date[1];
    from = date[0];
  }

  let config = {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  };

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASEURL}${url}?deviceId=${activeDevice}&from=${from}&to=${to}`,config
    );
    if(response && response?.data){
      ApiResponse(response?.data)
    }
  } catch (error) {
    ApiResponse([])
    console.error("Error fetching trips data:", error);
  }
};

export const encodeData = (data) => {
  return Base64.encode(data);
};

export const decodeData = (encodedData) => {
  return Base64.decode(encodedData);
};

export const getCookie = (name) => {
  return Cookies.get(name);
};
