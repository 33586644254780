import React, { useState, useEffect } from "react";
import GoogleMap from "google-maps-react-markers";
import { ThreeDots, SearchIcon, CarLogo } from "../../assets/svgLogos";
import { FaMapMarkerAlt } from "react-icons/fa";
import SpeedMeter from "../../assets/images/speedMeter.svg";
import Fuel from "../../assets/images/fuel.svg";
import Battery from "../../assets/images/battery.svg";
import BatteryBackup from "../../assets/images/battery-backup.svg";
import CarImg from "../../assets/images/car.svg";
import moment from "moment";

const dropdownOptions = [
  "Bing",
  "Google Hybrid",
  "Bing Hybrid",
  "Open Street Map",
];

const Marker = ({ text }) => (
  <div className="marker-icon grow">
    <div className="w-[40px] h-[40px] rounded-full bg-[##aad360] flex justify-center items-center">
      <img src={CarImg} className="h-full w-full" alt="" />
    </div>
  </div>
);

function MapComponent({ activeDevice }) {
  const [tab, setTab] = useState(1);
  const [dropdown, setDropdown] = useState(false);
  const [deviceDetail, setDeviceDetail] = useState();
  const [marker, setMarker] = useState(false)

  useEffect(() => {
    if (activeDevice) {
      setDeviceDetail(activeDevice);
      if(activeDevice?.latitude && activeDevice?.longitude){
        setMarker(true)
      }else{
        setMarker(false)
      }
    }
  }, [activeDevice]);

  return (
    <div className="relative w-full h-full">
      {marker == true && (
        <GoogleMap
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          defaultCenter={{
            lat: deviceDetail?.latitude ?? 0.3633926808834076,
            lng: deviceDetail?.longitude ?? 32.59920120239258,
          }}
          defaultZoom={14}
        >
            <Marker
              lat={deviceDetail?.latitude}
              lng={deviceDetail?.longitude}
            />
        </GoogleMap>
      )}
      {marker == false &&(
        <GoogleMap
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        defaultCenter={{
          lat: 0.3633926808834076,
          lng: 32.59920120239258,
        }}
        defaultZoom={14}
      >
      </GoogleMap>
      )}
      {activeDevice && (
        <div className="absolute z-30 top-[30px] left-[30px] bg-white px-7 py-5 shadow-md rounded-md text-black">
          <div>
            <div className="flex items-center gap-3">
              <CarLogo />
              <div>
                <p className="text-[18px] font-bold">{activeDevice?.name}</p>
                <p>{activeDevice?.id}</p>
              </div>
            </div>
            <div className="mt-5">
              <ul className="flex flex-col gap-2">
                <li className="flex justify-between">
                  <span className="w-[130px]">Driver:</span>
                  <span className="w-[165px] whitespace-normal">
                    {activeDevice?.driver ?? "--"}
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="w-[130px]">Time:</span>
                  <span className="w-[165px] whitespace-normal">
                    {activeDevice?.fixTime
                      ? moment(activeDevice.fixTime).format(
                          "YYYY-MM-DD hh:mm:ss"
                        )
                      : "--"}
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="w-[130px]">GPS time:</span>
                  <span className="w-[165px] whitespace-normal">
                    {activeDevice?.deviceTime
                      ? moment(activeDevice.deviceTime).format(
                          "YYYY-MM-DD hh:mm:ss"
                        )
                      : "--"}
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="w-[130px]">Geofence:</span>
                  <span className="w-[165px] whitespace-normal">
                    {activeDevice?.geofence ?? "--"}
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="w-[130px]">Address:</span>
                  <span className="w-[165px] whitespace-normal">
                    {activeDevice?.address ?? "--"}
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="w-[130px]">Lat/Lan:</span>
                  <span className="w-[165px] whitespace-normal">
                    {activeDevice?.latitude} / {activeDevice?.longitude}
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="w-[130px]">Status:</span>
                  <span className="w-[165px] whitespace-normal">
                    {activeDevice?.status ?? "--"}
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="w-[130px]">
                    Odometer {"("}km{")"}:
                  </span>
                  <span className="w-[165px] whitespace-normal">
                    {activeDevice?.odometer ?? "--"}
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="w-[130px]">Engine Hours:</span>
                  <span className="w-[165px] whitespace-normal">
                    {activeDevice?.duration ?? "--"}
                  </span>
                </li>
              </ul>
            </div>
            <div className="flex items-start justify-between mt-5 gap-8">
              <div className="flex flex-col items-center gap-1">
                <img className="h-[21px]" src={SpeedMeter} alt="feature logo" />
                <p className="text-[13.5px] text-[#333333]">
                  {activeDevice?.speed ?? "0"} km/hr
                </p>
                <p
                  className="text-[12px]"
                  style={{ color: "rgba(51,51,51,70%)" }}
                >
                  Speed
                </p>
              </div>
              <div className="flex flex-col items-center gap-1">
                <img className="h-[21px]" src={Fuel} alt="feature logo" />
                <p className="text-[13.5px] text-[#333333]">
                  {activeDevice?.fuel ?? "0"} Lit
                </p>
                <p
                  className="text-[12px]"
                  style={{ color: "rgba(51,51,51,70%)" }}
                >
                  Fuel
                </p>
              </div>
              <div className="flex flex-col items-center gap-1">
                <img className="h-[21px]" src={Battery} alt="feature logo" />
                <p className="text-[13.5px] text-[#333333]">
                  {activeDevice?.battery ?? "0"}V
                </p>
                <p
                  className="text-[12px]"
                  style={{ color: "rgba(51,51,51,70%)" }}
                >
                  Battery Voltage
                </p>
              </div>
              <div className="flex flex-col items-center gap-1">
                <img
                  className="h-[21px]"
                  src={BatteryBackup}
                  alt="feature logo"
                />
                <p className="text-[13.5px] text-[#333333]">
                  {activeDevice?.backup ?? "0"}V
                </p>
                <p
                  className="text-[12px]"
                  style={{ color: "rgba(51,51,51,70%)" }}
                >
                  Battery Backup
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="absolute top-8 right-8 z-10 flex items-center gap-x-5">
        <div
          className="w-[450px] h-[60px] bg-white flex items-center"
          style={{ borderRadius: "30px" }}
        >
          <button
            className={`w-[240px] h-full text-[28px] ${
              tab === 1 ? "bg-[#180E45] text-white" : "bg-white text-[#180E45]"
            }`}
            style={{ borderRadius: "30px" }}
            onClick={() => {
              setTab(1);
            }}
          >
            Map
          </button>
          <button
            className={`w-[240px] h-full text-[28px] ${
              tab === 2 ? "bg-[#180E45] text-white" : "bg-white text-[#180E45]"
            }`}
            style={{ borderRadius: "30px" }}
            onClick={() => {
              setTab(2);
            }}
          >
            Satellite
          </button>
        </div>
        <div className="relative">
          <button
            className="w-[60px] h-[60px] rounded-full bg-[#FFC200] flex justify-center items-center"
            onClick={() => {
              setDropdown(!dropdown);
            }}
          >
            <ThreeDots />
          </button>
          {dropdown && dropdown === true && (
            <div className="absolute top-16 right-0">
              <ul className="bg-white px-7 pt-5 rounded-md flex flex-col gap-5">
                {dropdownOptions &&
                  dropdownOptions.length > 0 &&
                  dropdownOptions.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className="flex items-center gap-2 whitespace-nowrap"
                      >
                        <input
                          type="radio"
                          id={item}
                          name="dropdownOption"
                          className="w-[25px] h-[25px] p-2"
                        />
                        {item}
                      </li>
                    );
                  })}
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center bg-white h-[40px] rounded-md absolute left-10 bottom-10">
        <SearchIcon />
        <input
          type="text"
          className="border-none outline-none bg-transparent"
          placeholder="Search address here"
        />
      </div>
    </div>
  );
}

export default MapComponent;
