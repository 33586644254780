import React, { useEffect, useRef, useState } from "react";
import GoogleMap from "google-maps-react-markers";
import { GetReportApi } from "../../services/apis";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { FaMapMarkerAlt } from "react-icons/fa";
import CarImg from "../../assets/images/car.svg";
import Playback from "./playback";
import { CarLogo } from "../../assets/svgLogos";
import SpeedMeter from "../../assets/images/speedMeter.svg";
import Fuel from "../../assets/images/fuel.svg";
import Battery from "../../assets/images/battery.svg";
import BatteryBackup from "../../assets/images/battery-backup.svg";

const Marker = ({ text }) => (
  <div className="marker-icon grow">
    <FaMapMarkerAlt style={{ fontSize: "24px" }} />
  </div>
);

const Carplayback = () => {
  return (
    <div className="w-[30px] h-[30px] rounded-full bg-[##aad360] flex justify-center items-center">
      <img src={CarImg} alt="" />
    </div>
  );
};

const detailFeatures = [
  { id: 1, logo: SpeedMeter, name: "Speed", capacity: "9.00 km/hr" },
  { id: 1, logo: Fuel, name: "Fuel", capacity: "69.80 Lit" },
  { id: 1, logo: Battery, name: "Battery Voltage", capacity: "12.62V" },
  { id: 1, logo: BatteryBackup, name: "Battery Backup", capacity: "12.78V" },
];

function DeepDive() {
  const location = useLocation();
  const [deviceId, setDeviceId] = useState(null);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [stops, setStops] = useState([]);
  const mapRef = useRef();
  const polylineRef = useRef(null);
  const [path, setPath] = useState([]);
  const [animationIndex, setAnimationIndex] = useState(0);
  const [center, setCenter] = useState();
  const [playback, setPlayback] = useState(false);
  const [zoom, setZoom] = useState(14);
  const [mapReady, setMapReady] = useState(false);
  const [activePointDetail, setActivePointDetail] = useState();
  const [deviceName, setDeviceName] = useState("")

  const formatDateString = (dateString) => {
    if (!dateString) return "";
    const [date, time] = dateString.split("T");
    const [year, month, day] = date.split("-");
    const [splitTime, extra] = time.split(".");
    return `${year}-${month}-${day}T${splitTime}Z`;
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const deviceIdParam = searchParams.get("deviceId");
    const startParam = searchParams.get("start");
    const endParam = searchParams.get("end");
    const deviceName = searchParams.get("deviceName")

    setDeviceName(deviceName)
    setDeviceId(deviceIdParam);
    setStart(formatDateString(startParam));
    setEnd(formatDateString(endParam));
  }, [location.search]);

  const ApiResponse = (res) => {
    if (res && res.length > 0) {
      setStops([...res]);
      setCenter({
        longitude: res[0]?.longitude,
        latitude: res[0]?.latitude,
      });
      setActivePointDetail(res[0]);
      const pathCoordinates = res.map((stop) => ({
        lat: stop.latitude,
        lng: stop.longitude,
      }));
      setPath(pathCoordinates);
    }
  };

  const fetchPositions = async () => {
    const date = [start, end];
    await GetReportApi("api/positions", date, deviceId, ApiResponse);
  };

  useEffect(() => {
    (async () => {
      if (start && end && deviceId) {
        fetchPositions();
      }
    })();
  }, [start, end, deviceId]);

  useEffect(() => {
    let playBackAnimation;

    if (playback) {
      playBackAnimation = setInterval(() => {
        setAnimationIndex((prevIndex) => {
          let newIndex = prevIndex + 1;
          if (newIndex < stops.length) {
            setCenter({
              latitude: stops[newIndex].latitude,
              longitude: stops[newIndex].longitude,
            });
            setActivePointDetail(stops[newIndex]);
            return newIndex;
          } else {
            setCenter({
              latitude: stops[0].latitude,
              longitude: stops[0].longitude,
            });
            setActivePointDetail(stops[0]);
            setPlayback(false);
            clearInterval(playBackAnimation);
            return 0;
          }
        });
      }, 1000);
    } else {
      clearInterval(playBackAnimation);
    }

    return () => clearInterval(playBackAnimation);
  }, [playback, stops.length]);

  const onGoogleApiLoaded = ({ map, maps }) => {
    mapRef.current = map;
    setMapReady(true);
    if (path.length > 1) {
      polylineRef.current = new maps.Polyline({
        path: path,
        geodesic: true,
        strokeColor: "#0000FF",
        strokeOpacity: 0.8,
        strokeWeight: 4,
      });
      polylineRef.current.setMap(map);
    }
  };

  useEffect(() => {
    if (mapReady && path.length > 1) {
      polylineRef.current.setPath(path);
    }
  }, [path, mapReady]);

  useEffect(() => {
    console.log(activePointDetail, "activePointDetail");
  }, [activePointDetail]);

  return (
    <div>
      <div className="h-[80vh] relative">
        {stops && stops.length > 0 && (
          <>
            <GoogleMap
              apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
              defaultCenter={{
                lat: stops[0]?.latitude ?? 10.99835602,
                lng: stops[0]?.longitude ?? 77.01502627,
              }}
              defaultZoom={zoom}
              options={stops}
              mapMinHeight="70vh"
              onGoogleApiLoaded={onGoogleApiLoaded}
              onChange={(map) => console.log("Map moved", map)}
            >
              {stops.map(({ latitude, longitude, name }, index) => {
                return (
                  <Marker
                    key={index}
                    lat={latitude}
                    lng={longitude}
                    markerId={name}
                  />
                );
              })}
              <Carplayback
                lat={center?.latitude ?? stops[0]?.latitude}
                lng={center?.longitude ?? stops[0]?.longitude}
              />
            </GoogleMap>
          </>
        )}
        <div className="absolute z-30 top-5 left-5 bg-white px-7 py-5 shadow-md rounded-md text-black">
          <div>
            <div className="flex items-center gap-3">
              <CarLogo />
              <div>
                <p className="text-[18px] font-bold">
                  {deviceName ?? ""}
                </p>
                <p>{activePointDetail?.id}</p>
              </div>
            </div>
            <div className="mt-5">
              <ul className="flex flex-col gap-2">
                <li className="flex justify-between">
                  <span className="w-[130px]">Driver:</span>
                  <span className="w-[165px] whitespace-normal">
                    {activePointDetail?.driver ?? "--"}
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="w-[130px]">Time:</span>
                  <span className="w-[165px] whitespace-normal">
                    {activePointDetail?.fixTime
                      ? moment(activePointDetail.fixTime).format(
                          "YYYY-MM-DD hh:mm:ss"
                        )
                      : "--"}
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="w-[130px]">GPS time:</span>
                  <span className="w-[165px] whitespace-normal">
                    {activePointDetail?.deviceTime
                      ? moment(activePointDetail.deviceTime).format(
                          "YYYY-MM-DD hh:mm:ss"
                        )
                      : "--"}
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="w-[130px]">Geofence:</span>
                  <span className="w-[165px] whitespace-normal">
                    {activePointDetail?.geofence ?? "--"}
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="w-[130px]">Address:</span>
                  <span className="w-[165px] whitespace-normal">
                    {activePointDetail?.address ?? "--"}
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="w-[130px]">Lat/Lan:</span>
                  <span className="w-[165px] whitespace-normal">
                    {activePointDetail?.latitude} /{" "}
                    {activePointDetail?.longitude}
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="w-[130px]">Status:</span>
                  <span className="w-[165px] whitespace-normal">
                    {activePointDetail?.status ?? "--"}
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="w-[130px]">
                    Odometer {"("}km{")"}:
                  </span>
                  <span className="w-[165px] whitespace-normal">
                    {activePointDetail?.odometer ?? "--"}
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="w-[130px]">Engine Hours:</span>
                  <span className="w-[165px] whitespace-normal">
                    {activePointDetail?.duration ?? "--"}
                  </span>
                </li>
              </ul>
            </div>
            <div className="flex items-start justify-between mt-5 gap-8">
              <div className="flex flex-col items-center gap-1">
                <img className="h-[21px]" src={SpeedMeter} alt="feature logo" />
                <p className="text-[13.5px] text-[#333333]">
                  {activePointDetail?.speed ?? "0"} km/hr
                </p>
                <p
                  className="text-[12px]"
                  style={{ color: "rgba(51,51,51,70%)" }}
                >
                  Speed
                </p>
              </div>
              <div className="flex flex-col items-center gap-1">
                <img className="h-[21px]" src={Fuel} alt="feature logo" />
                <p className="text-[13.5px] text-[#333333]">
                  {activePointDetail?.fuel ?? "0"} Lit
                </p>
                <p
                  className="text-[12px]"
                  style={{ color: "rgba(51,51,51,70%)" }}
                >
                  Fuel
                </p>
              </div>
              <div className="flex flex-col items-center gap-1">
                <img className="h-[21px]" src={Battery} alt="feature logo" />
                <p className="text-[13.5px] text-[#333333]">
                  {activePointDetail?.battery ?? "0"}V
                </p>
                <p
                  className="text-[12px]"
                  style={{ color: "rgba(51,51,51,70%)" }}
                >
                  Battery Voltage
                </p>
              </div>
              <div className="flex flex-col items-center gap-1">
                <img
                  className="h-[21px]"
                  src={BatteryBackup}
                  alt="feature logo"
                />
                <p className="text-[13.5px] text-[#333333]">
                  {activePointDetail?.backup ?? "0"}V
                </p>
                <p
                  className="text-[12px]"
                  style={{ color: "rgba(51,51,51,70%)" }}
                >
                  Battery Backup
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end items-center">
        <Playback
          totalDuration={stops.length}
          onPlay={() => {
            setPlayback(true);
          }}
          onStop={() => {
            setPlayback(false);
          }}
        />
      </div>
    </div>
  );
}

export default DeepDive;
