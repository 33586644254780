import React, { useEffect, useState } from "react";
import DonutChart from "../Piechart/DonutChart";
import { GetApi } from "../../services/apis";
import NotFound from '../../assets/images/notFound.svg'
import moment from "moment";
import { GrStatusGood } from "react-icons/gr";
import { IoLocationSharp } from "react-icons/io5";
import { BsFuelPump } from "react-icons/bs";

const LiveOverviewTableHeaders = [
  "Unit name",
  "Asset type",
  "Asset model",
  "Status",
  "Time in status (h)",
  "Odometer (Km)",
  "Battery voltage",
  "Fuel",
  "Fuel capacity",
  "Time",
];

const LiveOverviewGraphs = [
  { title: "Status", color: "#f1dd53", centerText: <GrStatusGood/>, icon: true},
  { title: "Odometer (Km)", color: "#7da632", centerText: <IoLocationSharp/>, icon: true },
  { title: "Fuel Status(Liter)", color: "#d2df2f", centerText: <BsFuelPump />, icon: true },
];


function LiveOverview({ activeDevice, title }) {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [dataNotFound, setDataNotFound] = useState(false)

  const ApiResponse = (res) => {
    if (res && res?.data && res?.data?.length > 0) {
      console.log(res.data,"data")
      setTableData(res?.data);
      setLoading(false);
    }else{
      setLoading(false);
    }
  };

  const GetMaintenance = () => {
    setLoading(true);
    if(title === "Live Overview"){
      GetApi(`api/positions?deviceId=${activeDevice?.deviceId}`, ApiResponse);
      GetApi(``)
    }
  };

  const GetTest = () => {
    // setLoading(true);
    setDataNotFound(true)
  };

  useEffect(()=>{
console.log(activeDevice,"activeDevice")
  },[activeDevice])

  return (
    <div className="w-[100%]">
      <div className="h-[50px] bg-white border px-5 flex justify-end items-center w-full">
        <button
          disabled={loading}
          className="h-[35px] bg-[#180e45] text-white rounded-md px-5 flex items-center gap-2"
          onClick={() => {
            if(title == "Test"){
              GetTest()
            }else{
              GetMaintenance();
            }
          }}
        >
          {loading == true && <div className="small_loader"></div>}
          Execute
        </button>
      </div>
      <div className="flex items-center gap-x-20 p-5 border-t border-b">
            <p>{title}- {activeDevice?.name}</p>
            <p>
              Total Selected Vehicle:{" "}
              {activeDevice && !activeDevice?.group ? 1 : ""}
            </p>
          </div>
      {tableData && tableData.length > 0 && (
        <>
          <div className="bg-white flex flex-wrap ml-5">
            {LiveOverviewGraphs && LiveOverviewGraphs.length > 0 && LiveOverviewGraphs.map((items, index)=>{
              return <DonutChart key={index} data={items} />
            })}
          </div>
          <div className="lg:w-[1000px] md:w-[700px] w-[100%] overflow-x-auto py-5 table-container ml-5">
            <table className="table-auto bg-white rounded-md">
              <thead>
                <tr className="bg-[#180E45] text-white">
                  {LiveOverviewTableHeaders && LiveOverviewTableHeaders.length > 0 && (
                    LiveOverviewTableHeaders.map((item, index) => {
                      return (
                        <th key={index} className="text-center px-10">
                          {item}
                        </th>
                      );
                    })
                  )}
                </tr>
              </thead>
              <tbody>
                {tableData &&
                  tableData.length > 0 &&
                  tableData.map((item, index) => {
                    return (
                      <tr key={index} className="border">
                        <td className="px-10">
                          <div className="flex justify-center gap-2 w-[170px]">
                            <span className="whitespace-normal">
                              {activeDevice?.name}
                            </span>
                          </div>
                        </td>
                        <td className="px-10 whitespace-normal text-center">
                          {activeDevice?.deviceDetail?.category ?? "N/A"}
                        </td>
                        <td className="px-10 whitespace-normal text-center">
                        {activeDevice?.deviceDetail?.model ?? "N/A"}
                        </td>
                        <td className="px-10 whitespace-normal text-center">
                          {activeDevice?.deviceDetail?.status ?? "N/A"}
                        </td>
                        <td className="px-10 whitespace-normal text-center">
                          N/A
                        </td>
                        <td className="px-10 whitespace-normal text-center">
                          {item?.attributes?.odometer ?? "N/A"}
                        </td>
                        <td className="px-10 whitespace-normal text-center">
                          N/A
                        </td>
                        <td className="px-10 whitespace-normal text-center">
                          {item?.attributes?.fuel1 ?? "N/A"}
                        </td>
                        <td className="px-10 whitespace-normal text-center">
                          {item?.attributes?.input ?? "N/A"}
                        </td>
                        <td className="px-10 whitespace-nowrap text-center">
                        {item?.deviceTime ? moment(item?.deviceTime).format("DD-MM-YYYY hh:mm:ss") : "N/A"}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </>
      )}
      {tableData?.length <= 0 && dataNotFound == true &&(
        <div className="h-[50vh] w-full flex flex-col justify-center items-center gap-2">
          <div className="w-[120px] h-[120px] rounded-full mb-3 bg-[#ececec] overflow-hidden flex justify-center items-center">
            <img src={NotFound} className="w-[70%] h-[70%]" alt="" />
          </div>
          <h2 className="font-bold text-[20px]">No data found</h2>
          <p>Please modify selection criteria and re-enter</p>
        </div>
      )}
    </div>
  );
}

export default LiveOverview;
