import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/logo.png";
import DemoImages from "../../assets/images/login_demo.png";
import { MdOutlineEmail } from "react-icons/md";
import Input from "../../Components/Common/Input";
import { Link } from "react-router-dom";
import { PostApi, encodeData } from "../../services/apis";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

function Login() {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    Email: "",
    Password: "",
  });

  const [error, setError] = useState({
    Email: "",
    Password: "",
  });

  const [loading, setLoading] = useState(false);

  const handleInputValue = (key, value) => {
    setValues({ ...values, [key]: value });
  };

  const FormValidation = () => {
    let validate = true;
    let ErrorFields = error;
    if (values.Email === "") {
      validate = false;
      ErrorFields = { ...ErrorFields, Email: "Field Required" };
    }
    if (values.Password === "") {
      validate = false;
      ErrorFields = { ...ErrorFields, Password: "Field Required" };
    }
    setError({ ...ErrorFields });

    return validate;
  };

  const ApiResponse = (res) => {
    if (res && res.data) {
      const encode = encodeData(values.Password)
      Cookies.set("email",values.Email)
      Cookies.set("token",encode)
      navigate('/detail');
    }
  };

  const handleLogin = async () => {
    setLoading(true);
    const Validate = FormValidation();
    if (Validate === true) {
      await PostApi(
        "api/session",
        { email: values.Email, password: values.Password },
        setLoading,
        ApiResponse
      );
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="flex h-[100vh] items-center">
      <div className="lg:w-[580px] w-full bg-[#FAFAFA] h-full flex flex-col justify-center sm:px-14 px-5">
        <img src={Logo} className="w-[145px]" alt="logo" />
        <h2 className="text-[42px]">Log In</h2>
        <div className="mt-8">
          <Input
            name="Email"
            logo={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.8604 9.11462L13.5388 12.5101C12.7223 13.136 11.5735 13.136 10.757 12.5101L6.39893 9.11462"
                  stroke="#7B6F72"
                  stroke-width="1.50367"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.8956 20.5316C19.8537 20.5395 21.8473 18.1911 21.8473 15.3048V8.84997C21.8473 5.96368 19.8537 3.6153 16.8956 3.6153H7.34673C4.38867 3.6153 2.39502 5.96368 2.39502 8.84997V15.3048C2.39502 18.1911 4.38867 20.5395 7.34673 20.5316H16.8956Z"
                  stroke="#7B6F72"
                  stroke-width="1.50367"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
            placeholder="Email"
            onchange={(key, value) => {
              handleInputValue(key, value);
            }}
            Error={error}
            setError={setError}
            type="email"
          />
          <div className="mt-5">
            <Input
              name="Password"
              logo={
                <svg
                  width="24"
                  height="23"
                  viewBox="0 0 24 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.2301 8.87048V6.85274C16.2301 4.49103 14.3148 2.57573 11.9531 2.57573C9.59136 2.56539 7.66854 4.47129 7.6582 6.83394V6.85274V8.87048"
                    stroke="#7B6F72"
                    stroke-width="1.50367"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.5347 19.9612H8.35375C6.38581 19.9612 4.79004 18.3664 4.79004 16.3975V12.3667C4.79004 10.3979 6.38581 8.80304 8.35375 8.80304H15.5347C17.5027 8.80304 19.0984 10.3979 19.0984 12.3667V16.3975C19.0984 18.3664 17.5027 19.9612 15.5347 19.9612Z"
                    stroke="#7B6F72"
                    stroke-width="1.50367"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.946 13.3393V15.4266"
                    stroke="#7B6F72"
                    stroke-width="1.50367"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              }
              placeholder="Password"
              onchange={(key, value) => {
                handleInputValue(key, value);
              }}
              Error={error}
              setError={setError}
              type="password"
            />
          </div>
          <div className="flex justify-end">
            <Link to="" className="text-[#ADA4A5] mt-2">
              Forget password?
            </Link>
          </div>
          <button
            className="h-[60px] w-full rounded-xl flex items-center justify-center gap-5 bg-[#FFC200] text-white mt-6 text-[23px]"
            onClick={() => {
              handleLogin();
            }}
            disabled={loading}
          >
            {loading && loading == true && <div className="loader"></div>}
            Sign In
          </button>
          <div className="flex justify-center items-center mt-5">
            <p
              className="sm:text-[18px] text-[14px]"
              style={{ color: "rgba(71,85,105,80%)" }}
            >
              Don't have an account
            </p>
            <Link
              to=""
              className="sm:text-[20px] text-[16px] font-semibold ml-3"
            >
              Sign-up
            </Link>
          </div>
          <button className="h-[60px] w-full rounded-xl flex items-center justify-center bg-[#F14336] text-white mt-10 text-[16px]">
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-3"
            >
              <path
                d="M9.54073 16.1086L8.67048 19.3573L5.48975 19.4246C4.53918 17.6615 4 15.6443 4 13.5006C4 11.4278 4.50412 9.47301 5.39771 7.7518H5.39839L8.23013 8.27096L9.47061 11.0857C9.21098 11.8426 9.06947 12.6552 9.06947 13.5006C9.06957 14.4183 9.23579 15.2974 9.54073 16.1086Z"
                fill="white"
              />
              <path
                d="M28.7816 11.1648C28.9252 11.921 29.0001 12.7019 29.0001 13.5C29.0001 14.395 28.906 15.268 28.7267 16.11C28.1182 18.9755 26.5281 21.4777 24.3255 23.2483L24.3248 23.2477L20.7581 23.0657L20.2533 19.9144C21.7148 19.0573 22.8571 17.7159 23.4587 16.11H16.7744V11.1648H23.5563H28.7816Z"
                fill="white"
              />
              <path
                d="M24.3266 23.2476L24.3272 23.2483C22.185 24.9702 19.4637 26.0005 16.5014 26.0005C11.741 26.0005 7.60208 23.3397 5.49072 19.424L9.5417 16.108C10.5974 18.9254 13.3152 20.9309 16.5014 20.9309C17.871 20.9309 19.154 20.5607 20.255 19.9144L24.3266 23.2476Z"
                fill="white"
              />
              <path
                d="M24.4782 3.8776L20.4286 7.19295C19.2892 6.48072 17.9423 6.06928 16.4992 6.06928C13.2409 6.06928 10.4722 8.16686 9.46944 11.0853L5.39717 7.75137H5.39648C7.47693 3.74024 11.668 0.999756 16.4992 0.999756C19.5323 0.999756 22.3133 2.08017 24.4782 3.8776Z"
                fill="white"
              />
            </svg>
            Continue with Google
          </button>
          <button className="h-[60px] w-full rounded-xl flex items-center justify-center bg-black text-white mt-5 text-[16px]">
            <svg
              width="26"
              height="32"
              viewBox="0 0 26 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-3"
            >
              <path
                d="M13.2025 9.17045C11.9417 9.17045 9.99015 7.73699 7.93495 7.7888C5.22346 7.82334 2.7365 9.36042 1.33758 11.7956C-1.47753 16.6832 0.612211 23.9023 3.35824 27.8745C4.70534 29.8088 6.29424 31.9849 8.40125 31.9158C10.4219 31.8295 11.1818 30.6033 13.6342 30.6033C16.0694 30.6033 16.7602 31.9158 18.9018 31.864C21.0779 31.8295 22.4595 29.8952 23.7894 27.9436C25.3264 25.6984 25.9655 23.5223 26 23.4014C25.9482 23.3842 21.7687 21.778 21.7169 16.9422C21.6824 12.9009 25.0156 10.9666 25.171 10.8802C23.2712 8.09967 20.3525 7.7888 19.3335 7.71972C16.6739 7.51247 14.446 9.17045 13.2025 9.17045ZM17.6928 5.09458C18.8154 3.74748 19.5581 1.86498 19.3508 -0.000244141C17.7446 0.0688383 15.8103 1.07053 14.6532 2.41764C13.617 3.60931 12.7189 5.52635 12.9607 7.35703C14.7396 7.4952 16.5702 6.44169 17.6928 5.09458Z"
                fill="white"
              />
            </svg>
            Continue with Apple
          </button>
        </div>
      </div>
      <div className="hidden lg:flex flex-col items-center justify-center w-full">
        <h2 className="text-[45px] font-semibold text-center">
          Vehicles Tracking
        </h2>
        <p className="text-[23px] text-[#7E7E7E] w-[500px] text-center">
          Lorem ipsum dolor sit amet consectetur. Sapien quisque sit consectetur
          lectus
        </p>
        <img src={DemoImages} className="mt-10" alt="demo_img" />
      </div>
    </div>
  );
}

export default Login;
