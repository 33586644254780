import React, { useState, useEffect } from "react";
import { Vehicles, Truck, Bus, Locomotive } from "../../assets/svgLogos";
import Piechart from "../Piechart/DonutChart";
import Progressbar from "../Common/progressbar";
import PlaybackImg from "../../assets/images/playback.svg";
import moment from "moment";
import Card from "../Common/Card";

const TableHeader = [
  "Vehicle",
  "Utilization score",
  "Distance traveled (Km)",
  "Discipline score",
  "Driving time (h)",
  "Driving time percent",
  "Idle time (h)",
  "Idle time percent",
];

function Efficiency({ activeDevice, data, title }) {
  const [tableData, setTableData] = useState([]);
  const [summaryDetail, setSummaryDetail] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  const handleNavigate = (item) => {
    if(title === "Trips"){
      const url = `/deepDive?deviceId=${item?.deviceId}&deviceName=${item?.deviceName}&start=${item?.startTime}&end=${item?.endTime}`;
      window.open(url, "_blank");
    }
  };

  useEffect(() => {
    if (data && data.length > 0) {
      let totalTrips = 0;
      let activeOption = 0;
      let totalDistance = 0;
      let averageDistance = 0;
      let totalDuration = 0;
      let averageDuration = 0;

      setSummaryDetail([
        { title: "Active vehicles", value: totalTrips },
        { title: "Distance (Km)", value: activeOption },
        { title: "Utilization score", value: totalDistance },
        { title: "Discipline score", value: averageDistance },
        { title: "Diving time (h)", value: totalDuration },
        { title: "Net driving time (%)", value: averageDuration },
        { title: "Idle time (h)", value: averageDuration },
        { title: "Net idle time (%)", value: averageDuration },
      ]);
    }else{
      setSummaryDetail([
        { title: "Active vehicles", value: 0 },
        { title: "Distance (Km)", value: 0 },
        { title: "Utilization score", value: 0 },
        { title: "Discipline score", value: 0 },
        { title: "Diving time (h)", value: 0 },
        { title: "Net driving time (%)", value: 0 },
        { title: "Idle time (h)", value: 0 },
        { title: "Net idle time (%)", value: 0 },
      ]);
    }
  }, [data]);

  return (
    <>
      {tableData && tableData.length > 0 && (
        <div className="w-full h-full ml-5 overflow-x-hidden">
          <p className="font-bold text-[18px] mb-3 mt-5">Summary</p>
          <div className="flex gap-5">
            <Piechart data={{title: activeDevice?.name, color: "#82ca9d", centerText: "Time in Status"}} />
            <div className="mb-5 flex flex-wrap gap-5">
            {summaryDetail &&
              summaryDetail.length > 0 &&
              summaryDetail.map((item, index) => {
                return (
                  <Card key={index} title={item?.title} value={item?.value} />
                );
              })}
          </div>
          </div>
          <div className="max-w-[1000px] overflow-x-auto py-5 table-container">
            <table className="table-auto bg-white rounded-md">
              <thead>
                <tr className="bg-[#180E45] text-white">
                  {TableHeader &&
                    TableHeader.length > 0 &&
                    TableHeader.map((item, index) => {
                      return (
                        <th key={index} className="text-center px-10">
                          {item}
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                {tableData &&
                  tableData.length > 0 &&
                  tableData.map((item, index) => {
                    return (
                      <tr key={index} className="border">
                        <td className="px-10 text-center">
                          <div className="flex items-center justify-center gap-2 w-[170px]">
                                {activeDevice?.name ?? "N/A"}
                          </div>
                        </td>
                        <td className="px-10 whitespace-normal text-center">
                        N/A
                        </td>
                        <td className="px-10 whitespace-normal text-center">
                        N/A
                        </td>
                        <td className="px-10 whitespace-normal text-center">
                        N/A
                        </td>
                        <td className="px-10 whitespace-normal text-center">
                          N/A
                        </td>
                        <td className="px-10 whitespace-normal text-center">
                          N/A
                        </td>
                        <td className="px-10 whitespace-normal text-center">
                          N/A
                        </td>
                        <td className="px-10 whitespace-normal text-center">
                          N/A
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default Efficiency;
