import React from "react";
import ReactApexChart from "react-apexcharts";

class ApexChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "ADVISORY",
          data: [200, 220, 230, 210, 240, 250, 260],
        },
        {
          name: "CUSTOM",
          data: [150, 160, 170, 140, 180, 190, 200],
        },
        {
          name: "WARNING",
          data: [100, 110, 120, 90, 130, 140, 150],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "text",
          categories: [
            "",
            "29/05/24 14:00",
            "29/05/24 14:00",
            "29/05/24 14:00",
            "29/05/24 14:00",
          ],
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm",
          },
        },
        colors: ["#4285F4", "#34A853", "#EA4335"],
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100]
          }
        }
      },
    };
  }

  render() {
    return (
      <div className="card">
        <div id="chart" className="w-full h-full">
            <p className="text-[20px] font-bold">Events Count Trend</p>
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="area"
            height={350}
          />
        </div>
      </div>
    );
  }
}

export default ApexChart;