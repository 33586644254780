import React from "react";

function Progressbar({ item }) {
  return (
    <div className="w-full h-full">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
            <span>{item?.icon}</span>
            <span className="text-[20px]">{item?.name}</span>
        </div>
        <p className="text-[20px] m-0 p-0">{item?.average}</p>
      </div>
      <div className="w-full bg-[#3E3E3E] rounded-full h-2.5 dark:bg-gray-700 mt-3">
        <div className={`h-2.5 rounded-full`} style={{width: `${item?.average}px`, background: `${item?.color}`}}></div>
      </div>
    </div>
  );
}

export default Progressbar;
